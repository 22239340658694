$bg-less-dark: #2d3740;
$bg-dark: #262f36;
$secondary-bg: #646464;
$bg-less-light: #ddd;
$bg-light: #eee;
$primary: #ffc830;
$primary-hover: #eeae00;
$green: #00c200;
$green-hover: #00a000;
$red: #dd0404;
$red-hover: #c01111;
$purple: #9900f1;
$purple-hover: rgb(134, 0, 211);
//  #024F47, #2D3740, ochra - #c56b15, #ffc830, #3ca0d2
// #d4aa00, #b38f00
// #333, #252525
@font-face {
    font-family: "Alata";
    src: url(./Alata-Regular.ttf);
}
@keyframes loading-animation {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 0.7;
    }
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes goUp {
    0% {
        transform: translateY(0);
        rotate: 1 1 1 2deg;
    }
    50% {
        rotate: 1 1 1 -20deg;
        transform: translateY(30px);
    }
    100% {
        rotate: 1 1 1 2deg;
        transform: translateY(0);
    }
}

* {
    text-align: center;
    font-family: "Alata";
}

@mixin flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#light {
    background-color: $bg-less-light;
    color: $bg-dark;
}

#dark {
    background-color: $bg-less-dark;
    color: $bg-light;
}

body {
    margin: 0;
    padding: 0;
    background-color: $bg-less-dark;
}
.App {
    min-height: 100svh;
    overflow: hidden;
}
#container {
    @include flex;
    justify-content: space-between;
    min-height: 88svh;
    z-index: 1;
}

header {
    background-color: $bg-dark;
    @include flex;
    justify-content: space-around;
    width: 100vw;
    min-height: 12svh;
    z-index: 2;
}

#logo-header {
    z-index: 4;
    width: 40px;
    cursor: pointer;
}
#logo {
    z-index: 4;
    display: flex;
    align-items: center;
    cursor: pointer;
}
svg {
    // width: 100px;
    height: 60px;
    cursor: pointer;
}

nav {
    width: 70%;
    @include flex;
    z-index: 4;
}

main {
    min-width: 97vw;
    @include flex;
    flex-direction: column;
}

h2 {
    display: grid;
    place-content: center;
}

a,
button {
    position: relative;
    text-decoration: none;
    background-color: $primary;
    min-width: 130px;
    padding: 15px 5px;
    color: black;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
    transform: none;
    display: grid;
    place-content: center;
    &:hover {
        background-color: $primary-hover;
    }
    &::before {
        content: "";
        position: absolute;
        border-radius: 10px;
        inset: 0;
        border: $secondary-bg 4px solid;
        opacity: 0;
    }

    &:hover::before {
        animation: pulse 1s infinite ease-in-out;
    }
}
.rc-anchor-light.rc-anchor-normal {
    background: #00a000 !important;
}
.rc-anchor-light.rc-anchor-normal {
    width: 500px;
}
.fblogin {
    padding: 13px;
    margin: 0;
    width: 45px !important;
    height: 45px !important;
    min-width: 0;
    margin-bottom: 10px;
    border-radius: 30px;
    background-color: #318ab6;
    display: flex;
    color: white;
    margin-left: 10px;
    &:hover {
        background-color: #287399;
    }
    &:hover::before {
        animation: none;
    }
    div div svg g path {
        width: 16px;
    }
}
.display_social_flex {
    padding-left: 20px;
    width: 65%;
    display: flex;
    align-items: center;
    // justify-content: center;
}

.blank-interior {
    border: 2px solid $primary;
    background-color: transparent;
    color: $bg-less-light;
    &:hover {
        background-color: rgba(179, 143, 0, 0.4);
    }
    &::before {
        border: none;
        opacity: 0;
    }

    &:hover::before {
        animation: none;
    }
}
section {
    @include flex;
    margin: 2em 0;
}
form {
    z-index: 2;
}

article {
    @include flex;
    flex-direction: column;
    justify-content: center;
    height: 70vh;
    form {
        z-index: 3;
        height: auto;
        input {
            background-color: $bg-dark;
            margin: 0;
        }
    }
    h1 {
        font-size: 30px;
    }
    z-index: 2;
}
#buttons {
    @include flex;
}
#category-displayer {
    font-size: larger;
}
form {
    @include flex;
    width: min(500px, 50vw);
    flex-direction: column;
    // height: 50vh;
    border-radius: 10px;
    padding: 15px;
    color: $bg-light;
}

label {
    font-size: larger;
    color: $bg-light;
    text-align: left;
    text-decoration: underline;
    margin: 5px;
    width: 60%;
}

input {
    background-color: $bg-less-dark;
    border: none;
    border-bottom: 1px solid $bg-less-dark;
    width: 65%;
    font-size: 25px;
    text-align: left;
    margin-bottom: 30px;
    color: $bg-light;
    height: 40px;
    border-radius: 10px;
}
#light input[type="submit"],
input[type="submit"] {
    background-color: $primary;
    text-align: center;
    color: black;
    border-radius: 10px;
    padding: 15px 5px;
    border: none;
    font-size: medium;
    width: 40%;
    &:hover {
        background-color: $primary-hover;
    }
    height: auto;
    margin: 0;

    &::before {
        content: "";
        position: absolute;
        border-radius: 10px;
        inset: 0;
        border: $secondary-bg 4px solid;
        opacity: 0;
    }

    &:hover::before {
        animation: pulse 1s infinite ease-in-out;
    }
}

input[type="checkbox"] {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
}

#agreement {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 62%;
    p {
        text-align: left;
    }
}
.two_colums_photo_text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 70%;
    z-index: 3;
    place-content: center;
}
.right_column {
    width: 300px;
    // rotate: 1 1 1 -30deg;
    animation: goUp 5s infinite ease-in-out;
}
.left_column {
    text-align: left !important;

    h2 {
        display: block;
        text-align: left !important;
    }
    a {
        display: inline-block;
        max-width: 180px;
        min-width: 170px;
        margin-left: 0;
        margin-top: 10px;
    }
}
div.divider {
    min-height: 2px;
    background-color: $bg-less-light;
}

footer {
    background: url(./photos/wave-haikei1.svg);
    width: 100%;
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: 25% 50% 5% 20%;
    z-index: 0;
    position: relative;
}
textarea {
    text-align: left;
    border-radius: 10px;
    resize: none;
    width: 65%;
    height: 30%;
    border: none;
    background-color: $bg-less-dark;
    border-bottom: 1px solid $bg-less-dark;
    color: $bg-light;
}
#user {
    z-index: 4;
}
#user_header {
    max-width: 40px;
    background-color: $primary;
    padding: 5px;
    border-radius: 50%;
}
#header_user_info {
    z-index: 4;
    position: absolute;
    border-radius: 10px;
    color: $bg-light;
    display: none;
    padding: 5px;
    margin: 0;
    background-color: $bg-less-dark;
}
#user:hover #header_user_info {
    display: block;
    z-index: 4;
}
.dropdown-select {
    background-color: $bg-less-dark;
    width: 100px;
    display: grid;
    place-content: center;
    height: 30px;
    margin: 0;
    border-radius: 10px;
    z-index: 4;
    img {
        height: 20px;
    }
    p {
        margin: 0;
        padding: 0;
    }
}
.dropdown-option,
.current-option {
    z-index: 4;
    position: relative;
    border-radius: 10px;
    color: $bg-light;
    bottom: -100%;
    cursor: pointer;
    display: none;
    padding: 5px;
    margin: 0;
    background-color: $bg-less-dark;
    img {
        height: 20px;
    }
    p {
        font-size: 15px;
        padding: 0;
        margin: 0;
    }
    &:hover {
        background-color: $bg-dark;
    }
}
.current-option {
    bottom: 0;
}

.dropdown-select:hover .dropdown-option,
.current-option {
    position: relative;
    display: grid;
    grid-template-columns: 30px 60px;
    place-content: center;
}
.dropdown-select:hover .current-option {
    bottom: -100%;
}

.white-link,
.blue-link {
    background-color: transparent;
    min-width: auto;
    padding: 0;
    margin: 0;
    color: #3ca0d2;
    display: inline;
    &:hover {
        background-color: transparent;
        color: $primary;
    }
    &::before {
        display: none;
    }
}
.white-link {
    color: $bg-light;
    display: grid;
    place-content: center;
    min-width: 100px;
}
select {
    background-color: #444;
    color: $bg-light;
    border: none;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
}
#section {
    background-color: $bg-less-dark;
    color: $bg-less-light;
}

#cards-big-box {
    position: relative;
    background-color: $bg-dark;
    padding: 15px;
    border-radius: 10px;
    height: auto;
    @include flex;
    gap: 10px;
    z-index: 2;
}
@keyframes glow {
    0% {
        transform: translateY(-50px);
        transform: translateX(-400px);
    }
    100% {
        transform: translateY(800px);
    }
}
.card-box:last-of-type {
    border: 5px solid $primary;
    position: relative;
    overflow: hidden;
    &::after {
        content: "";
        width: 1000px;
        height: 20px;
        position: absolute;
        left: -160%;
        top: 0;
        transform-origin: (left);
        rotate: -45deg;
        background: linear-gradient(
            0,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
        animation: glow 3s infinite;
        animation-timing-function: ease-in;
    }
}

#start {
    min-width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: 0 20vw;
    padding: 0;
    display: grid;
    place-content: center;
    &::before {
        border-radius: 100%;
    }
}

.card-box {
    height: 50vh;
    border-radius: 10px;
}

.card-box {
    background-color: $bg-less-dark;
    height: inherit;
    min-width: 13vw;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    transition: transform 0.07s;
    div,
    span {
        display: grid;
        place-content: center;
    }
    &:hover {
        transform: scale(1.05);
    }
}

.visible {
    top: 0;
    display: grid;
    place-content: center;
    // grid-template-columns: 100px 0fr;
    background-color: rgba(50, 50, 50, 0.7);
    position: absolute;
    width: 100svw;
    min-height: 100%;
    overflow: hidden;
    z-index: 20;
    .back {
        position: absolute;
        top: 4px;
        left: 4px;
        min-width: 40px;
        width: 60px;
    }
    h2 {
        margin: 5px;
    }
    &-all {
        grid-template-columns: auto;
        a,
        button {
            min-width: 130px;
        }
    }
    .first-back {
        min-width: auto;
        width: 69.5vw;
        margin: 10px;
        position: absolute;
        top: 0;
        left: 14.5vw;
    }
}
.invisible {
    display: none;
    &-good {
        display: none;
    }
    &-bad {
        display: none;
    }
    &-again {
        display: none;
    }
}
.meanings {
    display: none;
}
.good {
    color: $green;
}
.bad {
    color: $red;
}
.again {
    color: $primary;
}
button.again {
    color: black;
}
.btn-visible {
    display: block;
}
.content-document {
    width: 70%;
    text-align: left;
    min-height: 100vh;
    padding-bottom: 120px;
    z-index: 3;
    h1,
    h2,
    p {
        display: block;
        text-align: left;
    }
    li {
        text-align: left;
    }
    a {
        display: inline;
    }
}
.modify {
    @include flex;
    position: relative;
    width: min(500px, 50vw);
    flex-direction: column;
    border-radius: 10px;
    min-height: 50vh;
    height: auto;
    padding: 10px;
    background-color: $bg-dark;
    margin: 10px;
    // z-index: 2;
    span {
        background-color: $bg-less-dark;
        width: 90%;
        height: 4px;
        margin: 5px;
    }
    div {
        width: 100%;
        @include flex;
        flex-direction: column;
    }
    p.bad {
        color: red;
        font-size: 16px;
        margin: 0;
    }
    span.btn-desc {
        background-color: transparent;
        width: 90%;
        height: auto;
        @include flex;
        justify-content: center;
    }
    .plus,
    .minus {
        font-size: large;
        width: 15px;
        min-width: auto;
        height: 15px;
        margin-bottom: 30px;
        padding: 15px;
        display: grid;
        cursor: pointer;
        place-content: center;
    }
    .meaning-box {
        flex-direction: row;
        width: 70%;
    }
    .card-btn {
        flex-direction: row;
        padding: 5px;
    }
}
#word,
#btn {
    text-align: center;
}
#word::placeholder {
    font-size: 25px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 25px;
}
.btn-desc {
    margin: 0;
    font-size: 14px;
}
.lesson,
.card {
    width: 70vw;
    background-color: $bg-dark;
    color: $bg-light;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 0.5fr 0.5fr 0.5fr;
    column-gap: 10px;
    place-content: center;
    margin: 10px;
    p {
        margin: 5px 0;
    }
    div {
        display: grid;
        place-content: center;
        line-break: anywhere;
    }
}
.first-back,
.card:first-of-type {
    // background: url(./photos/Vector\ 1.svg);
    // background-size: 1100px 150px;
    // background-repeat: no-repeat;
    // background-color: #048376;
    position: fixed;
    z-index: 5;
    top: 60px;
    left: 14.5vw;
    overflow: hidden;
}
#bg-card {
    bottom: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
}
.first-back {
    top: 0px;
    // min-width: auto;
    width: 69.5vw;
    left: 14.5vw;
}
.back {
    height: 30px; //BUGBUG
    img {
        height: 20px;
    }
}
.space {
    height: 140px;
}

//  #024F47, #2D3740, ochra - #c56b15, #ffc830, #3ca0d2
// #d4aa00, #b38f00
// #333, #252525
#search {
    grid-column-start: 3;
    grid-column-end: 5;
    align-self: center;
    text-align: center;
    margin: 10px;
    height: 40px;
    border-radius: 10px;
    border: #3ca0d2 2px solid;
}
#magnifier {
    place-self: center;
    height: 40px;
    // grid-column-start: 5;
    // grid-column-end: 5;
}
.divider {
    min-height: 4px;
    width: 90%;
    margin: 5px;
    background-color: $bg-dark;
}
.col {
    flex-direction: column;
    height: 16vh;
}
.loading {
    background-color: $secondary-bg;
    opacity: 0.7;
    animation: loading-animation 1s infinite alternate;
    pointer-events: none;
}
input[type="submit"].loading {
    background-color: $secondary-bg;
    text-align: center;
}
.loading-text {
    display: inline-block;
    height: auto;
    padding: 0 5px;
}

.photo-and-form {
    // position: relative;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bg-dark;
    box-shadow: 0 0 10px 5px black;
    color: $bg-less-light;
    margin-top: 5svh;
    overflow: hidden;
    z-index: 2;
    input {
        margin-bottom: 15px;
    }
    input[type="checkbox"] {
        margin-bottom: 0;
    }
}

.form-photo,
.pay-form {
    height: 76vh;
    width: 30vw;
    display: grid;
    place-content: center;
    h1 {
        margin-top: 30px;
        font-size: 30px;
        padding: 10px;
    }
    // box-shadow: 0 0 10px 5px black;
}
.payment_form {
    text-align: left;

    p {
        margin: 5px;
    }
}
.pay-form {
    padding: 0 30px;
    place-content: start;
    text-align: left;
}
// .polygon-login {
//     position: relative;
//     right: 10px;
//     top: 350px;
//     width: 100px;
//     rotate: 32deg;
// }
// .rectangle-login {
//     rotate: -21deg;
//     position: relative;
//     top: 200px;
//     width: 200px;
// }
// .star-login {
//     position: absolute;
//     bottom: 200px;
//     left: 150px;
//     width: 100px;
// }

.bg-for-parent1 {
    position: absolute;
    // height: 170px;
    width: 100%;
    bottom: 0;
    left: 0;
    opacity: 1;
    z-index: -5;
}
.bg-for-parent2 {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    z-index: -5;

    // filter: brightness(150%);
    // opacity: 0.7;
}
.sun,
.bush,
.tent {
    position: relative;
    bottom: -40px;
    left: 20px;
    width: 90%;
    height: 90%;
    padding: 10px;
}
.bush {
    width: 30%;
    height: 90%;
    left: 0;
}
.tent {
    left: 0;
    height: 90%;
    width: 50%;
}
.tent-and-bush {
    @include flex;
}
#land-photo {
    height: 40vh;
    // width: 50svw;
    box-shadow: 0px 0px 10px 1px black;
    margin-top: 10px;
    object-fit: scale-down;
    margin: 30px 0;
    width: auto;
}
.p-100-bottom {
    padding-bottom: 120px;
}
#landing-page {
    min-height: 80svh;
    position: relative;
    padding-bottom: 50px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
    h2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        a {
            max-width: 100px;
            align-self: center;
        }
    }
}
//  #024F47, #2D3740, ochra - #c56b15, #ffc830, #3ca0d2
// #d4aa00, #b38f00

#form-question {
    margin: 10px;
    min-height: 55vh;
    padding: 20px;
    width: min(700px, 60vw);
    z-index: 2;
    box-shadow: 0 0 10px 5px black;
    background-color: $bg-dark;
    input {
        margin-bottom: 15px;
    }
    iframe {
        margin-bottom: -70px;
    }
}

#reset-password {
    background-color: $bg-dark;
}

#refresh-btn {
    width: 30px;
    margin: auto;
}
.big-section-box,
.categories-box {
    background-color: $bg-dark;
    width: 90vw;
    @include flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 10px;
    position: relative;
    h2 {
        margin: 25px;
    }
    .divider {
        background-color: $bg-less-dark;
    }
}
.section-box {
    width: 100%;
    @include flex;
    align-items: center;
    .one-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $bg-less-dark;
        border-radius: 10px;
        justify-content: center;
        padding: 5px;
    }

    .unchangable {
        pointer-events: none;
        opacity: 0.7;
    }

    .select-fields {
        display: flex;
        color: $bg-light;
    }
    .section-btn {
        display: flex;
        flex-direction: column;
    }
}
#settings-section {
    width: 40px;
}
.settings-section {
    place-content: start center;
    min-width: 0;
    width: 60px;
    height: 100px;
    position: absolute;
    right: 80px;
    top: -50px;
    z-index: -1;
    transition: transform 1s ease-in-out;
    &,
    &:before {
        border-radius: 10px 10px 0 0;
    }
    &:hover {
        transform: translateY(-30px);
    }
}
.category {
    @include flex;
}

#category {
    font-size: larger;
    @include flex;
    select {
        margin: 0 0 0 15px;
        z-index: 2;
        padding: 12px;
    }
    a {
        min-width: auto;
        font-size: medium;
        position: relative;
        left: -30px;
        height: auto;
        padding: 0;
        width: 70px;
        z-index: 1;
    }
    img {
        padding: 3px 5px 3px 30px;
        height: 30px;
    }
}
.categories-box {
    height: auto;
    padding: 10px;
    input {
        margin: 20px;
        width: auto;
    }
}
.meaning {
    text-align: center;
}
#meaning {
    text-align: center;
}
.centered {
    text-align: center;
}
#confirmation {
    display: none;
    place-content: center;
    background-color: rgba(200, 200, 200, 0.7);
    border-radius: 10px;
    width: 100vw;
    height: 100vh;
    position: fixed;
    inset: 0;
    z-index: 20;
}

#switch-mode {
    display: grid;
    place-content: center;
    margin: 10px;
    padding: 2px;
    background-color: $bg-less-dark;
    cursor: pointer;
    border-radius: 50%;
    min-width: 45px;
    height: 45px;
    img {
        min-width: 35px;
        height: 35px;
    }
    &::before {
        border-radius: 50%;
    }
}

#panel {
    flex-direction: column;
    z-index: 3;
}
#payment-form {
    width: 100%;
}
/* blured photos */
/*
.blurred-img::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 2.5s infinite;
    background-color: $bg-light;
}

.blurred-img {
    background-image: url(./photos/astronaut-small.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    & img {
        transition: opacity 250ms ease-in-out;
        opacity: 0;
    }
}

.blurred-img.loaded::before {
    animation: none;
    content: none;
}

.blurred-img.loaded img {
    opacity: 1;
}
 blured photos */

/* hamburger menu for mobile */
@mixin hamburger-line($in_light: false) {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 3px;
    @if $in_light {
        background-color: $bg-dark;
    } @else {
        background-color: $bg-light;
    }
}
#hamburger {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 3px;
    width: auto;
    min-width: auto;
    pointer-events: none;
    display: none;
    z-index: 5;
}
#hamburger,
nav {
    transition: transform 0.3s 0.1s ease-in-out;
}
.hamburger-active {
    transform: translate(-130%);
}
#hamburger-box {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
}
.hamburger-line::after,
.hamburger-line::before,
.hamburger-line {
    @include hamburger-line;
}
#light .hamburger-line::after,
#light .hamburger-line::before,
#light .hamburger-line {
    @include hamburger-line(true);
}
.hamburger-line {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.1s 0.2s ease-in-out;
}
.hamburger-line::after,
.hamburger-line::before {
    content: "";
    left: 0;
    transition: transform 0.2s 0.2s ease-in-out;
}
.hamburger-line::after {
    top: -10px;
}
.hamburger-line::before {
    top: 10px;
}

.hamburger-active .hamburger-line {
    background-color: transparent;
}
#light .hamburger-active .hamburger-line {
    background-color: transparent;
}
.hamburger-active .hamburger-line::before {
    transform: translateY(-10px) rotate(-45deg);
}

.hamburger-active .hamburger-line::after {
    transform: translateY(10px) rotate(45deg);
}

#loading-page {
    position: absolute;
    height: 100%;
    width: 100%;
    place-content: center;
    z-index: 15;
    background-color: rgba(200, 200, 200, 0.7);
    display: none;
    h1 {
        text-align: center;
        color: #000;
    }
}
.icon {
    width: 25px;
}
.row {
    flex-direction: row;
}
@media (max-width: 1200px) {
    #header_user_info {
        right: 20px;
    }

    #landing-page {
        width: 95%;
    }
    .p-100-bottom {
        padding-bottom: 0px;
    }
}

@media (max-width: 850px) {
    .right_column {
        display: none;
    }
    .two_colums_photo_text {
        grid-template-columns: 1fr;
    }
    .payment_form {
        flex-direction: column;
        width: 90vw;
        .pay-form {
            width: 80vw;
            height: auto;
        }
        p {
            margin: 5px;
        }
    }
    .row {
        flex-direction: column;
    }
    footer {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    #land-photo {
        height: auto;
        width: 98svw;
        box-shadow: 0px 0px 10px 1px black;
        margin-top: 10px;
    }

    #search {
        grid-column-start: 2;
        grid-column-end: 6;
        align-self: center;
        width: auto;
    }
    .visible {
        grid-template-columns: auto;
        .back {
            min-width: 100px;
            width: auto;
        }
        button {
            max-width: 250px;
            padding: 15px 10px;
        }
        .back {
            width: auto;
            max-width: auto;
            padding: 15px 0;
            height: 20px;
        }
        .first-back {
            width: 98vw;
            padding: 15px 0;
            margin: 10px 3px;
            left: 0;
            height: 20px;
        }
        h3 {
            font-size: 20px;
        }
    }
    #user_header {
        max-width: 40px;
        background-color: $primary;
        padding: 5px;
        border-radius: 50%;
    }
    #header_user_info {
        z-index: 4;
        position: static;
        border-radius: 10px;
        color: $bg-light;
        display: block;
        padding: 5px;
        margin: 0;
        background-color: $bg-less-dark;
    }
    #user:hover #header_user_info {
        display: block;
    }

    #meaning {
        margin-top: 10px;
        text-align: center;
    }
    .space {
        height: 130px;
    }
    .card:first-of-type {
        left: 0;
        width: 99vw;
        top: 70px;
    }
    .category {
        @include flex;
        flex-direction: column;
    }
    section {
        margin: 0.5em;
    }
    #cards-big-box,
    .card-box {
        width: 90vw;
    }
    #cards-big-box {
        flex-direction: column;
        height: max-content;
        margin: 0;
    }
    .card-box {
        place-content: unset;
        @include flex;
        flex-direction: column;
        min-height: 9vh;
        .section-info {
            @include flex;
            width: 100%;
        }
        a {
            font-size: 15px;
            width: 50%;
        }
    }
    h3,
    h2 {
        margin: 1px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 17px;
    }
    a,
    button {
        text-decoration: none;
        background-color: $primary;
        min-width: 100px;
        padding: 10px 10px;
        color: black;
        border: none;
        &:hover {
            background-color: $primary-hover;
        }

        &:hover::before {
            animation: none;
        }
    }

    #start {
        min-width: 70px;
        height: 70px;
        border-radius: 100%;
        margin: 0 5vw;
        padding: 0;
        display: grid;
        place-content: center;
    }

    #hamburger {
        pointer-events: all;
        display: block;
    }
    nav {
        padding-top: 100px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 60px;
        background-color: $bg-less-dark;
        position: absolute;
        height: 100svh;
        top: 0;
        right: 0;
        width: 50%;
        transform-origin: right;
        transform: scale(0, 1);
        z-index: 4;
    }
    .active {
        transform: scale(1, 1);
    }

    .modify {
        width: 95vw;
    }
    .card {
        width: 100vw;
        column-gap: 8px;
        font-size: 14px;
        margin: 5px;
        grid-template-columns: 60px 1fr 1fr 40px 0.7fr 60px;
        a,
        button {
            font-size: 13px;
            display: grid;
            place-content: center;
            min-width: auto;
            padding: 10px;
        }
        h3 {
            margin: 20px 0;
        }
    }
    svg {
        width: 80px;
        height: 50px;
    }
    .form-photo {
        display: none;
    }
    #form-question,
    .user-form {
        width: 90vw;
        height: auto;
        padding: 15px 0;
    }
    #form-question {
        height: auto;
    }
    #light nav {
        background-color: $bg-less-light;
        color: $bg-less-dark;
    }
    .settings-section {
        right: 10px;
        width: 50px;
        transform: none;
    }
    #cards-big-box {
        margin-top: 1.2em;
    }
    #category {
        flex-direction: column;
        select {
            margin: 0;
        }
        a {
            left: 0;
            width: auto;
        }
        img {
            padding: 5px;
        }
    }
    .section-box {
        flex-direction: column;
        gap: 10px;
    }
    .big-section-box {
        height: auto;
    }
}

#light header,
#light #cards-big-box,
#light .modify,
#light .card,
#light .divider,
#light .section-box {
    background-color: $bg-light;
    color: $bg-dark;
}

#light {
    .dropdown-select,
    .dropdown-option,
    .current-option,
    select {
        background-color: #d1d1d1;
        color: $bg-dark;
    }

    .current-option:hover,
    .dropdown-option:hover,
    .dropdown-select:hover {
        background-color: #cecece;
    }
}
#light label {
    color: $bg-dark;
}

#light #section,
#light .card-box,
#light .modify span,
#light .photo-and-form,
#light.big-section-box,
#light .categories-box {
    background-color: $bg-less-light;
    color: $bg-dark;
}
#light span.btn-desc {
    background-color: $bg-light;
}

#light .one-section {
    background-color: $bg-less-light;
}
#light .big-section-box,
#light #form-question,
#light .categories-box {
    background-color: $bg-light;
}
#light textarea,
#light input,
#light .divider {
    background-color: $bg-less-light;
}
#light .blank-interior,
#light input,
#light textarea,
#light .white-link,
#light .select-fields {
    color: $bg-dark;
}
#light .white-link:hover {
    color: $primary-hover;
}
#light .lesson {
    color: $bg-dark;
    background-color: $bg-light;
}
