@font-face {
  font-family: "Alata";
  src: url(./Alata-Regular.ttf);
}
@keyframes loading-animation {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.7;
  }
}
@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes goUp {
  0% {
    transform: translateY(0);
    rotate: 1 1 1 2deg;
  }
  50% {
    rotate: 1 1 1 -20deg;
    transform: translateY(30px);
  }
  100% {
    rotate: 1 1 1 2deg;
    transform: translateY(0);
  }
}
* {
  text-align: center;
  font-family: "Alata";
}

#light {
  background-color: #ddd;
  color: #262f36;
}

#dark {
  background-color: #2d3740;
  color: #eee;
}

body {
  margin: 0;
  padding: 0;
  background-color: #2d3740;
}

.App {
  min-height: 100svh;
  overflow: hidden;
}

#container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  justify-content: space-between;
  min-height: 88svh;
  z-index: 1;
}

header {
  background-color: #262f36;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  justify-content: space-around;
  width: 100vw;
  min-height: 12svh;
  z-index: 2;
}

#logo-header {
  z-index: 4;
  width: 40px;
  cursor: pointer;
}

#logo {
  z-index: 4;
  display: flex;
  align-items: center;
  cursor: pointer;
}

svg {
  height: 60px;
  cursor: pointer;
}

nav {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 4;
}

main {
  min-width: 97vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

h2 {
  display: grid;
  place-content: center;
}

a,
button {
  position: relative;
  text-decoration: none;
  background-color: #ffc830;
  min-width: 130px;
  padding: 15px 5px;
  color: black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  transform: none;
  display: grid;
  place-content: center;
}
a:hover,
button:hover {
  background-color: #eeae00;
}
a::before,
button::before {
  content: "";
  position: absolute;
  border-radius: 10px;
  inset: 0;
  border: #646464 4px solid;
  opacity: 0;
}
a:hover::before,
button:hover::before {
  animation: pulse 1s infinite ease-in-out;
}

.rc-anchor-light.rc-anchor-normal {
  background: #00a000 !important;
}

.rc-anchor-light.rc-anchor-normal {
  width: 500px;
}

.fblogin {
  padding: 13px;
  margin: 0;
  width: 45px !important;
  height: 45px !important;
  min-width: 0;
  margin-bottom: 10px;
  border-radius: 30px;
  background-color: #318ab6;
  display: flex;
  color: white;
  margin-left: 10px;
}
.fblogin:hover {
  background-color: #287399;
}
.fblogin:hover::before {
  animation: none;
}
.fblogin div div svg g path {
  width: 16px;
}

.display_social_flex {
  padding-left: 20px;
  width: 65%;
  display: flex;
  align-items: center;
}

.blank-interior {
  border: 2px solid #ffc830;
  background-color: transparent;
  color: #ddd;
}
.blank-interior:hover {
  background-color: rgba(179, 143, 0, 0.4);
}
.blank-interior::before {
  border: none;
  opacity: 0;
}
.blank-interior:hover::before {
  animation: none;
}

section {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 2em 0;
}

form {
  z-index: 2;
}

article {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
  z-index: 2;
}
article form {
  z-index: 3;
  height: auto;
}
article form input {
  background-color: #262f36;
  margin: 0;
}
article h1 {
  font-size: 30px;
}

#buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#category-displayer {
  font-size: larger;
}

form {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: min(500px, 50vw);
  flex-direction: column;
  border-radius: 10px;
  padding: 15px;
  color: #eee;
}

label {
  font-size: larger;
  color: #eee;
  text-align: left;
  text-decoration: underline;
  margin: 5px;
  width: 60%;
}

input {
  background-color: #2d3740;
  border: none;
  border-bottom: 1px solid #2d3740;
  width: 65%;
  font-size: 25px;
  text-align: left;
  margin-bottom: 30px;
  color: #eee;
  height: 40px;
  border-radius: 10px;
}

#light input[type=submit],
input[type=submit] {
  background-color: #ffc830;
  text-align: center;
  color: black;
  border-radius: 10px;
  padding: 15px 5px;
  border: none;
  font-size: medium;
  width: 40%;
  height: auto;
  margin: 0;
}
#light input[type=submit]:hover,
input[type=submit]:hover {
  background-color: #eeae00;
}
#light input[type=submit]::before,
input[type=submit]::before {
  content: "";
  position: absolute;
  border-radius: 10px;
  inset: 0;
  border: #646464 4px solid;
  opacity: 0;
}
#light input[type=submit]:hover::before,
input[type=submit]:hover::before {
  animation: pulse 1s infinite ease-in-out;
}

input[type=checkbox] {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}

#agreement {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 62%;
}
#agreement p {
  text-align: left;
}

.two_colums_photo_text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 70%;
  z-index: 3;
  place-content: center;
}

.right_column {
  width: 300px;
  animation: goUp 5s infinite ease-in-out;
}

.left_column {
  text-align: left !important;
}
.left_column h2 {
  display: block;
  text-align: left !important;
}
.left_column a {
  display: inline-block;
  max-width: 180px;
  min-width: 170px;
  margin-left: 0;
  margin-top: 10px;
}

div.divider {
  min-height: 2px;
  background-color: #ddd;
}

footer {
  background: url(./photos/wave-haikei1.svg);
  width: 100%;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 25% 50% 5% 20%;
  z-index: 0;
  position: relative;
}

textarea {
  text-align: left;
  border-radius: 10px;
  resize: none;
  width: 65%;
  height: 30%;
  border: none;
  background-color: #2d3740;
  border-bottom: 1px solid #2d3740;
  color: #eee;
}

#user {
  z-index: 4;
}

#user_header {
  max-width: 40px;
  background-color: #ffc830;
  padding: 5px;
  border-radius: 50%;
}

#header_user_info {
  z-index: 4;
  position: absolute;
  border-radius: 10px;
  color: #eee;
  display: none;
  padding: 5px;
  margin: 0;
  background-color: #2d3740;
}

#user:hover #header_user_info {
  display: block;
  z-index: 4;
}

.dropdown-select {
  background-color: #2d3740;
  width: 100px;
  display: grid;
  place-content: center;
  height: 30px;
  margin: 0;
  border-radius: 10px;
  z-index: 4;
}
.dropdown-select img {
  height: 20px;
}
.dropdown-select p {
  margin: 0;
  padding: 0;
}

.dropdown-option,
.current-option {
  z-index: 4;
  position: relative;
  border-radius: 10px;
  color: #eee;
  bottom: -100%;
  cursor: pointer;
  display: none;
  padding: 5px;
  margin: 0;
  background-color: #2d3740;
}
.dropdown-option img,
.current-option img {
  height: 20px;
}
.dropdown-option p,
.current-option p {
  font-size: 15px;
  padding: 0;
  margin: 0;
}
.dropdown-option:hover,
.current-option:hover {
  background-color: #262f36;
}

.current-option {
  bottom: 0;
}

.dropdown-select:hover .dropdown-option,
.current-option {
  position: relative;
  display: grid;
  grid-template-columns: 30px 60px;
  place-content: center;
}

.dropdown-select:hover .current-option {
  bottom: -100%;
}

.white-link,
.blue-link {
  background-color: transparent;
  min-width: auto;
  padding: 0;
  margin: 0;
  color: #3ca0d2;
  display: inline;
}
.white-link:hover,
.blue-link:hover {
  background-color: transparent;
  color: #ffc830;
}
.white-link::before,
.blue-link::before {
  display: none;
}

.white-link {
  color: #eee;
  display: grid;
  place-content: center;
  min-width: 100px;
}

select {
  background-color: #444;
  color: #eee;
  border: none;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
}

#section {
  background-color: #2d3740;
  color: #ddd;
}

#cards-big-box {
  position: relative;
  background-color: #262f36;
  padding: 15px;
  border-radius: 10px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  z-index: 2;
}

@keyframes glow {
  0% {
    transform: translateY(-50px);
    transform: translateX(-400px);
  }
  100% {
    transform: translateY(800px);
  }
}
.card-box:last-of-type {
  border: 5px solid #ffc830;
  position: relative;
  overflow: hidden;
}
.card-box:last-of-type::after {
  content: "";
  width: 1000px;
  height: 20px;
  position: absolute;
  left: -160%;
  top: 0;
  transform-origin: left;
  rotate: -45deg;
  background: linear-gradient(0, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  animation: glow 3s infinite;
  animation-timing-function: ease-in;
}

#start {
  min-width: 100px;
  height: 100px;
  border-radius: 100%;
  margin: 0 20vw;
  padding: 0;
  display: grid;
  place-content: center;
}
#start::before {
  border-radius: 100%;
}

.card-box {
  height: 50vh;
  border-radius: 10px;
}

.card-box {
  background-color: #2d3740;
  height: inherit;
  min-width: 13vw;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  transition: transform 0.07s;
}
.card-box div,
.card-box span {
  display: grid;
  place-content: center;
}
.card-box:hover {
  transform: scale(1.05);
}

.visible {
  top: 0;
  display: grid;
  place-content: center;
  background-color: rgba(50, 50, 50, 0.7);
  position: absolute;
  width: 100svw;
  min-height: 100%;
  overflow: hidden;
  z-index: 20;
}
.visible .back {
  position: absolute;
  top: 4px;
  left: 4px;
  min-width: 40px;
  width: 60px;
}
.visible h2 {
  margin: 5px;
}
.visible-all {
  grid-template-columns: auto;
}
.visible-all a,
.visible-all button {
  min-width: 130px;
}
.visible .first-back {
  min-width: auto;
  width: 69.5vw;
  margin: 10px;
  position: absolute;
  top: 0;
  left: 14.5vw;
}

.invisible {
  display: none;
}
.invisible-good {
  display: none;
}
.invisible-bad {
  display: none;
}
.invisible-again {
  display: none;
}

.meanings {
  display: none;
}

.good {
  color: #00c200;
}

.bad {
  color: #dd0404;
}

.again {
  color: #ffc830;
}

button.again {
  color: black;
}

.btn-visible {
  display: block;
}

.content-document {
  width: 70%;
  text-align: left;
  min-height: 100vh;
  padding-bottom: 120px;
  z-index: 3;
}
.content-document h1,
.content-document h2,
.content-document p {
  display: block;
  text-align: left;
}
.content-document li {
  text-align: left;
}
.content-document a {
  display: inline;
}

.modify {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  width: min(500px, 50vw);
  flex-direction: column;
  border-radius: 10px;
  min-height: 50vh;
  height: auto;
  padding: 10px;
  background-color: #262f36;
  margin: 10px;
}
.modify span {
  background-color: #2d3740;
  width: 90%;
  height: 4px;
  margin: 5px;
}
.modify div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.modify p.bad {
  color: red;
  font-size: 16px;
  margin: 0;
}
.modify span.btn-desc {
  background-color: transparent;
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  justify-content: center;
}
.modify .plus,
.modify .minus {
  font-size: large;
  width: 15px;
  min-width: auto;
  height: 15px;
  margin-bottom: 30px;
  padding: 15px;
  display: grid;
  cursor: pointer;
  place-content: center;
}
.modify .meaning-box {
  flex-direction: row;
  width: 70%;
}
.modify .card-btn {
  flex-direction: row;
  padding: 5px;
}

#word,
#btn {
  text-align: center;
}

#word::-moz-placeholder {
  font-size: 25px;
}

#word::placeholder {
  font-size: 25px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 25px;
}

.btn-desc {
  margin: 0;
  font-size: 14px;
}

.lesson,
.card {
  width: 70vw;
  background-color: #262f36;
  color: #eee;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr 0.5fr 0.5fr;
  -moz-column-gap: 10px;
       column-gap: 10px;
  place-content: center;
  margin: 10px;
}
.lesson p,
.card p {
  margin: 5px 0;
}
.lesson div,
.card div {
  display: grid;
  place-content: center;
  line-break: anywhere;
}

.first-back,
.card:first-of-type {
  position: fixed;
  z-index: 5;
  top: 60px;
  left: 14.5vw;
  overflow: hidden;
}

#bg-card {
  bottom: 0;
  position: absolute;
  z-index: -1;
  width: 100%;
}

.first-back {
  top: 0px;
  width: 69.5vw;
  left: 14.5vw;
}

.back {
  height: 30px;
}
.back img {
  height: 20px;
}

.space {
  height: 140px;
}

#search {
  grid-column-start: 3;
  grid-column-end: 5;
  align-self: center;
  text-align: center;
  margin: 10px;
  height: 40px;
  border-radius: 10px;
  border: #3ca0d2 2px solid;
}

#magnifier {
  place-self: center;
  height: 40px;
}

.divider {
  min-height: 4px;
  width: 90%;
  margin: 5px;
  background-color: #262f36;
}

.col {
  flex-direction: column;
  height: 16vh;
}

.loading {
  background-color: #646464;
  opacity: 0.7;
  animation: loading-animation 1s infinite alternate;
  pointer-events: none;
}

input[type=submit].loading {
  background-color: #646464;
  text-align: center;
}

.loading-text {
  display: inline-block;
  height: auto;
  padding: 0 5px;
}

.photo-and-form {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #262f36;
  box-shadow: 0 0 10px 5px black;
  color: #ddd;
  margin-top: 5svh;
  overflow: hidden;
  z-index: 2;
}
.photo-and-form input {
  margin-bottom: 15px;
}
.photo-and-form input[type=checkbox] {
  margin-bottom: 0;
}

.form-photo,
.pay-form {
  height: 76vh;
  width: 30vw;
  display: grid;
  place-content: center;
}
.form-photo h1,
.pay-form h1 {
  margin-top: 30px;
  font-size: 30px;
  padding: 10px;
}

.payment_form {
  text-align: left;
}
.payment_form p {
  margin: 5px;
}

.pay-form {
  padding: 0 30px;
  place-content: start;
  text-align: left;
}

.bg-for-parent1 {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: -5;
}

.bg-for-parent2 {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  opacity: 0.3;
  z-index: -5;
}

.sun,
.bush,
.tent {
  position: relative;
  bottom: -40px;
  left: 20px;
  width: 90%;
  height: 90%;
  padding: 10px;
}

.bush {
  width: 30%;
  height: 90%;
  left: 0;
}

.tent {
  left: 0;
  height: 90%;
  width: 50%;
}

.tent-and-bush {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#land-photo {
  height: 40vh;
  box-shadow: 0px 0px 10px 1px black;
  margin-top: 10px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  margin: 30px 0;
  width: auto;
}

.p-100-bottom {
  padding-bottom: 120px;
}

#landing-page {
  min-height: 80svh;
  position: relative;
  padding-bottom: 50px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 60%;
}
#landing-page h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#landing-page h2 a {
  max-width: 100px;
  align-self: center;
}

#form-question {
  margin: 10px;
  min-height: 55vh;
  padding: 20px;
  width: min(700px, 60vw);
  z-index: 2;
  box-shadow: 0 0 10px 5px black;
  background-color: #262f36;
}
#form-question input {
  margin-bottom: 15px;
}
#form-question iframe {
  margin-bottom: -70px;
}

#reset-password {
  background-color: #262f36;
}

#refresh-btn {
  width: 30px;
  margin: auto;
}

.big-section-box,
.categories-box {
  background-color: #262f36;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 10px;
  margin: 10px;
  position: relative;
}
.big-section-box h2,
.categories-box h2 {
  margin: 25px;
}
.big-section-box .divider,
.categories-box .divider {
  background-color: #2d3740;
}

.section-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  align-items: center;
}
.section-box .one-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2d3740;
  border-radius: 10px;
  justify-content: center;
  padding: 5px;
}
.section-box .unchangable {
  pointer-events: none;
  opacity: 0.7;
}
.section-box .select-fields {
  display: flex;
  color: #eee;
}
.section-box .section-btn {
  display: flex;
  flex-direction: column;
}

#settings-section {
  width: 40px;
}

.settings-section {
  place-content: start center;
  min-width: 0;
  width: 60px;
  height: 100px;
  position: absolute;
  right: 80px;
  top: -50px;
  z-index: -1;
  transition: transform 1s ease-in-out;
}
.settings-section, .settings-section:before {
  border-radius: 10px 10px 0 0;
}
.settings-section:hover {
  transform: translateY(-30px);
}

.category {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#category {
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
#category select {
  margin: 0 0 0 15px;
  z-index: 2;
  padding: 12px;
}
#category a {
  min-width: auto;
  font-size: medium;
  position: relative;
  left: -30px;
  height: auto;
  padding: 0;
  width: 70px;
  z-index: 1;
}
#category img {
  padding: 3px 5px 3px 30px;
  height: 30px;
}

.categories-box {
  height: auto;
  padding: 10px;
}
.categories-box input {
  margin: 20px;
  width: auto;
}

.meaning {
  text-align: center;
}

#meaning {
  text-align: center;
}

.centered {
  text-align: center;
}

#confirmation {
  display: none;
  place-content: center;
  background-color: rgba(200, 200, 200, 0.7);
  border-radius: 10px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  z-index: 20;
}

#switch-mode {
  display: grid;
  place-content: center;
  margin: 10px;
  padding: 2px;
  background-color: #2d3740;
  cursor: pointer;
  border-radius: 50%;
  min-width: 45px;
  height: 45px;
}
#switch-mode img {
  min-width: 35px;
  height: 35px;
}
#switch-mode::before {
  border-radius: 50%;
}

#panel {
  flex-direction: column;
  z-index: 3;
}

#payment-form {
  width: 100%;
}

/* blured photos */
/*
.blurred-img::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 2.5s infinite;
    background-color: $bg-light;
}

.blurred-img {
    background-image: url(./photos/astronaut-small.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    & img {
        transition: opacity 250ms ease-in-out;
        opacity: 0;
    }
}

.blurred-img.loaded::before {
    animation: none;
    content: none;
}

.blurred-img.loaded img {
    opacity: 1;
}
 blured photos */
/* hamburger menu for mobile */
#hamburger {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 3px;
  width: auto;
  min-width: auto;
  pointer-events: none;
  display: none;
  z-index: 5;
}

#hamburger,
nav {
  transition: transform 0.3s 0.1s ease-in-out;
}

.hamburger-active {
  transform: translate(-130%);
}

#hamburger-box {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
}

.hamburger-line::after,
.hamburger-line::before,
.hamburger-line {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #eee;
}

#light .hamburger-line::after,
#light .hamburger-line::before,
#light .hamburger-line {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #262f36;
}

.hamburger-line {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.1s 0.2s ease-in-out;
}

.hamburger-line::after,
.hamburger-line::before {
  content: "";
  left: 0;
  transition: transform 0.2s 0.2s ease-in-out;
}

.hamburger-line::after {
  top: -10px;
}

.hamburger-line::before {
  top: 10px;
}

.hamburger-active .hamburger-line {
  background-color: transparent;
}

#light .hamburger-active .hamburger-line {
  background-color: transparent;
}

.hamburger-active .hamburger-line::before {
  transform: translateY(-10px) rotate(-45deg);
}

.hamburger-active .hamburger-line::after {
  transform: translateY(10px) rotate(45deg);
}

#loading-page {
  position: absolute;
  height: 100%;
  width: 100%;
  place-content: center;
  z-index: 15;
  background-color: rgba(200, 200, 200, 0.7);
  display: none;
}
#loading-page h1 {
  text-align: center;
  color: #000;
}

.icon {
  width: 25px;
}

.row {
  flex-direction: row;
}

@media (max-width: 1200px) {
  #header_user_info {
    right: 20px;
  }
  #landing-page {
    width: 95%;
  }
  .p-100-bottom {
    padding-bottom: 0px;
  }
}
@media (max-width: 850px) {
  .right_column {
    display: none;
  }
  .two_colums_photo_text {
    grid-template-columns: 1fr;
  }
  .payment_form {
    flex-direction: column;
    width: 90vw;
  }
  .payment_form .pay-form {
    width: 80vw;
    height: auto;
  }
  .payment_form p {
    margin: 5px;
  }
  .row {
    flex-direction: column;
  }
  footer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  #land-photo {
    height: auto;
    width: 98svw;
    box-shadow: 0px 0px 10px 1px black;
    margin-top: 10px;
  }
  #search {
    grid-column-start: 2;
    grid-column-end: 6;
    align-self: center;
    width: auto;
  }
  .visible {
    grid-template-columns: auto;
  }
  .visible .back {
    min-width: 100px;
    width: auto;
  }
  .visible button {
    max-width: 250px;
    padding: 15px 10px;
  }
  .visible .back {
    width: auto;
    max-width: auto;
    padding: 15px 0;
    height: 20px;
  }
  .visible .first-back {
    width: 98vw;
    padding: 15px 0;
    margin: 10px 3px;
    left: 0;
    height: 20px;
  }
  .visible h3 {
    font-size: 20px;
  }
  #user_header {
    max-width: 40px;
    background-color: #ffc830;
    padding: 5px;
    border-radius: 50%;
  }
  #header_user_info {
    z-index: 4;
    position: static;
    border-radius: 10px;
    color: #eee;
    display: block;
    padding: 5px;
    margin: 0;
    background-color: #2d3740;
  }
  #user:hover #header_user_info {
    display: block;
  }
  #meaning {
    margin-top: 10px;
    text-align: center;
  }
  .space {
    height: 130px;
  }
  .card:first-of-type {
    left: 0;
    width: 99vw;
    top: 70px;
  }
  .category {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
  section {
    margin: 0.5em;
  }
  #cards-big-box,
  .card-box {
    width: 90vw;
  }
  #cards-big-box {
    flex-direction: column;
    height: -moz-max-content;
    height: max-content;
    margin: 0;
  }
  .card-box {
    place-content: unset;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    min-height: 9vh;
  }
  .card-box .section-info {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
  }
  .card-box a {
    font-size: 15px;
    width: 50%;
  }
  h3,
  h2 {
    margin: 1px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
  a,
  button {
    text-decoration: none;
    background-color: #ffc830;
    min-width: 100px;
    padding: 10px 10px;
    color: black;
    border: none;
  }
  a:hover,
  button:hover {
    background-color: #eeae00;
  }
  a:hover::before,
  button:hover::before {
    animation: none;
  }
  #start {
    min-width: 70px;
    height: 70px;
    border-radius: 100%;
    margin: 0 5vw;
    padding: 0;
    display: grid;
    place-content: center;
  }
  #hamburger {
    pointer-events: all;
    display: block;
  }
  nav {
    padding-top: 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
    background-color: #2d3740;
    position: absolute;
    height: 100svh;
    top: 0;
    right: 0;
    width: 50%;
    transform-origin: right;
    transform: scale(0, 1);
    z-index: 4;
  }
  .active {
    transform: scale(1, 1);
  }
  .modify {
    width: 95vw;
  }
  .card {
    width: 100vw;
    -moz-column-gap: 8px;
         column-gap: 8px;
    font-size: 14px;
    margin: 5px;
    grid-template-columns: 60px 1fr 1fr 40px 0.7fr 60px;
  }
  .card a,
  .card button {
    font-size: 13px;
    display: grid;
    place-content: center;
    min-width: auto;
    padding: 10px;
  }
  .card h3 {
    margin: 20px 0;
  }
  svg {
    width: 80px;
    height: 50px;
  }
  .form-photo {
    display: none;
  }
  #form-question,
  .user-form {
    width: 90vw;
    height: auto;
    padding: 15px 0;
  }
  #form-question {
    height: auto;
  }
  #light nav {
    background-color: #ddd;
    color: #2d3740;
  }
  .settings-section {
    right: 10px;
    width: 50px;
    transform: none;
  }
  #cards-big-box {
    margin-top: 1.2em;
  }
  #category {
    flex-direction: column;
  }
  #category select {
    margin: 0;
  }
  #category a {
    left: 0;
    width: auto;
  }
  #category img {
    padding: 5px;
  }
  .section-box {
    flex-direction: column;
    gap: 10px;
  }
  .big-section-box {
    height: auto;
  }
}
#light header,
#light #cards-big-box,
#light .modify,
#light .card,
#light .divider,
#light .section-box {
  background-color: #eee;
  color: #262f36;
}

#light .dropdown-select,
#light .dropdown-option,
#light .current-option,
#light select {
  background-color: #d1d1d1;
  color: #262f36;
}
#light .current-option:hover,
#light .dropdown-option:hover,
#light .dropdown-select:hover {
  background-color: #cecece;
}

#light label {
  color: #262f36;
}

#light #section,
#light .card-box,
#light .modify span,
#light .photo-and-form,
#light.big-section-box,
#light .categories-box {
  background-color: #ddd;
  color: #262f36;
}

#light span.btn-desc {
  background-color: #eee;
}

#light .one-section {
  background-color: #ddd;
}

#light .big-section-box,
#light #form-question,
#light .categories-box {
  background-color: #eee;
}

#light textarea,
#light input,
#light .divider {
  background-color: #ddd;
}

#light .blank-interior,
#light input,
#light textarea,
#light .white-link,
#light .select-fields {
  color: #262f36;
}

#light .white-link:hover {
  color: #eeae00;
}

#light .lesson {
  color: #262f36;
  background-color: #eee;
}/*# sourceMappingURL=index.css.map */